export default p => {
  let dotsPerColumn;
  let lastWindowWidth;

  const dotPlacement = 12;
  const primaryHue = 220;
  const secondaryHue = 360;
  const saturation = 50;
  const lightness = 80;

  const defaultWidth = 1400;
  const defaultHeight = 600;
  const smallHeight = 440;

  const dotsPerColumnDefault = 38;
  const dotsPerColumnSmall = 22;

  p.setup = function() {
    lastWindowWidth = p.windowWidth;
    if (p.windowWidth <= 670) {
      p.createCanvas(p.windowWidth + 100, smallHeight);
      dotsPerColumn = dotsPerColumnSmall;
    } else {
      p.createCanvas(defaultWidth, defaultHeight);
      dotsPerColumn = dotsPerColumnDefault;
    }
    p.noLoop();
    p.colorMode(p.HSL);
  };

  const drawDots = () => {
    const fadeSteps = p.width * 0.2;

    for (var x = dotPlacement / 2; x < p.width; x += dotPlacement) {
      let yOffset = Math.ceil(p.random(-4, 4));
      for (var y = 1; y < dotsPerColumn; y++) {
        p.strokeWeight(p.random(8, 14));
        if (x < fadeSteps) {
          p.stroke(
            p.lerp(primaryHue, secondaryHue, x / p.width),
            saturation,
            lightness,
            p.random(0, 0.8) - p.lerp(1, 0, x / fadeSteps)
          );
        } else if (x > p.width - fadeSteps) {
          p.stroke(
            p.lerp(primaryHue, secondaryHue, x / p.width),
            saturation,
            lightness,
            p.random(0, 0.8) - p.lerp(1, 0, (p.width - x) / fadeSteps)
          );
        } else {
          p.stroke(
            p.lerp(primaryHue, secondaryHue, x / p.width),
            saturation,
            lightness,
            p.random(0, 0.8)
          );
        }
        p.point(x, (yOffset + y) * dotPlacement + p.random(1, 12) + 40);
      }
    }
  };

  p.draw = function() {
    drawDots();
  };

  p.windowResized = function() {
    // scrolling on iOS triggers a window.resize event for some reason
    // check that window has actually resized before proceeding

    // Notes:
    // Chrome on iOS does not register new width on orientation change until scroll happens
    // Unknown what Android / Firefox do
    if (p.windowWidth !== lastWindowWidth) {
      if (p.windowWidth <= 670) {
        p.resizeCanvas(p.windowWidth + 100, smallHeight);
        dotsPerColumn = dotsPerColumnSmall;
      } else {
        p.resizeCanvas(defaultWidth, defaultHeight);
        dotsPerColumn = dotsPerColumnDefault;
      }
      p.clear();
      p.redraw();
      lastWindowWidth = p.windowWidth;
    }
  };
};
