export default p => {
  let lastWindowWidth = window.innerWidth;
  let a, inc, amp;

  p.setup = function() {
    p.noLoop();
    p.colorMode(p.HSL);
    p.strokeWeight(1);
    sizeSketch();
  };

  p.myCustomRedrawAccordingToNewPropsHandler = function(props, windowWidth) {
    if (windowWidth && lastWindowWidth !== windowWidth) {
      resetSketch(windowWidth);
    }
  };

  const sizeSketch = () => {
    if (lastWindowWidth <= 670) {
      p.resizeCanvas(lastWindowWidth, 400);
      amp = 100;
      inc = p.TWO_PI / 100.0;

    } else {
      p.resizeCanvas(lastWindowWidth, 558);
      amp = 170;
      inc = p.TWO_PI / 160.0;
    }
  };

  const resetSketch = size => {
    p.clear();
    p.redraw();
    lastWindowWidth = size;
    sizeSketch();
  };

  const sineBars = () => {
    a = 0.0;
    for (var i = 0; i < p.width; i = i + 8) {
      p.stroke(192, 32, 60);
      p.line(i + 4, p.height / 2 - 16, i + 4, (p.height / 2 - 16) + -p.sin(a) * amp);

      p.stroke(193, 36, 74);
      p.line(i - 4, p.height / 2 - 16, i - 4, (p.height / 2 - 16) + p.sin(a) * amp);

      p.stroke(192, 32, 68);
      p.line(i + 8, p.height / 2 - 16, i + 8, (p.height / 2 - 16) + -p.cos(a) * amp);

      a = a + inc;
    }
  };

  p.draw = function() {
    sineBars();
  };

  p.windowResized = function() {
    // scrolling on iOS triggers a window.resize event for some reason
    // check that window has actually resized before proceeding
    // Notes:
    // Chrome on iOS does not register new width on orientation change until scroll happens
    // Unknown what Android / Firefox do
    if (p.windowWidth !== lastWindowWidth) {
      resetSketch(p.windowWidth);
    }
  };
};
