// Function to create a gaussian distribution 
// e.g. to use as a radial gradient mask
const makeGaussian = (amplitude, x0, y0, sigmaX, sigmaY, direction) => {
  return function(amplitude, x0, y0, sigmaX, sigmaY, x, y) {
    var exponent = Math.pow(
      -(
        Math.pow(x - x0, 2) / (2 * Math.pow(sigmaX, 2)) +
        Math.pow(y - y0, 2) / (2 * Math.pow(sigmaY, 2))
      ),
      direction
    );
    return amplitude * Math.pow(Math.E, exponent);
  }.bind(null, amplitude, x0, y0, sigmaX, sigmaY);
};

export default makeGaussian;