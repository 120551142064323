import makeGaussian from "./utils/gaussian";

export default p => {
  // Need this for any p5 classes. Doesn't pass down from the p5component, not sure why.
  var p5 = require("p5");

  const maxSpeed = 10;
  const magnitude = 1.2;
  let frameStop = 200;

  const inc = 0.1;
  const scl = 50;
  let zoff = 0;

  let numParticles, cols, rows, mask, particles, flowfield;
  let lastWindowWidth = window.innerWidth;

  p.myCustomRedrawAccordingToNewPropsHandler = function(props, windowWidth) {
    if (windowWidth && lastWindowWidth !== windowWidth) {
      p.clear();
      p.noLoop();
      lastWindowWidth = windowWidth;
      resetSketch();
    }
  };

  p.setup = function() {
    p.disableFriendlyErrors = true;
    p.colorMode(p.HSL);
    resetSketch();
  };

  p.draw = function() {
    drawParticles();
    if (p.frameCount >= frameStop) {
      p.noLoop();
    }
  };

  p.windowResized = function() {
    // scrolling on iOS triggers a window.resize event for some reason
    // check that window has actually resized before proceeding

    // Notes:
    // Chrome on iOS does not register new width on orientation change until scroll happens
    // Unknown what Android / Firefox do
    if (p.windowWidth !== lastWindowWidth) {
      lastWindowWidth = p.windowWidth;
      p.clear();
      p.noLoop();
      resetSketch();
    }
  };

  const sizeSketch = () => {
    if (lastWindowWidth <= 670) {
      p.resizeCanvas(lastWindowWidth, 400);
      numParticles = 260;
    } else if (lastWindowWidth <= 1024) {
      p.resizeCanvas(lastWindowWidth, 600);
      numParticles = 600;
    } else {
      p.resizeCanvas(lastWindowWidth, 600);
      numParticles = 800;
    }
  };

  const resetSketch = () => {

    particles = [];

    sizeSketch();

    mask = makeGaussian(0.14, p.windowWidth, 0, p.windowWidth, p.height / 2, 1);

    cols = Math.floor(p.width / scl);
    rows = Math.floor(p.height / scl);

    flowfield = new Array(cols * rows);

    for (var i = 0; i < numParticles; i++) {
      particles[i] = new Particle();
    }
    p.frameCount = 0;

    p.loop();
  };

  const drawParticles = () => {
    var yoff = 0;
    for (var y = 0; y < rows; y++) {
      var xoff = 0;
      for (var x = 0; x < cols; x++) {
        var index = x + y * cols;
        var angle = p.noise(xoff, yoff, zoff) * p.TWO_PI * 4;
        var v = p5.Vector.fromAngle(angle);
        v.setMag(magnitude);
        flowfield[index] = v;
        xoff += inc;
      }
      yoff += inc;
      zoff += 0.0003;
    }

    for (var i = 0; i < particles.length; i++) {
      particles[i].follow(flowfield);
      particles[i].update();
      particles[i].edges();
      particles[i].show();
    }
  };

  // Particle object
  const Particle = function() {
    this.pos = p.createVector(p.random(p.width), p.random(p.height));
    this.vel = p.createVector(0, 0);
    this.acc = p.createVector(0, 0);
    this.maxspeed = maxSpeed;

    this.prevPos = this.pos.copy();

    this.update = function() {
      this.vel.add(this.acc);
      this.vel.limit(this.maxspeed);
      this.pos.add(this.vel);
      this.acc.mult(0);
    };

    this.follow = function(vectors) {
      var x = Math.floor(this.pos.x / scl);
      var y = Math.floor(this.pos.y / scl);
      var index = x + y * cols;
      var force = vectors[index];
      this.applyForce(force);
    };

    this.applyForce = function(force) {
      this.acc.add(force);
    };

    this.show = function() {
      p.stroke(12, 30, 60, mask(this.pos.x, this.pos.y));
      p.strokeWeight(0.5);
      p.line(this.pos.x, this.pos.y, this.prevPos.x, this.prevPos.y);
      this.updatePrev();
    };

    this.updatePrev = function() {
      this.prevPos.x = this.pos.x;
      this.prevPos.y = this.pos.y;
    };

    this.edges = function() {
      if (this.pos.x > p.width) {
        this.pos.x = 0;
        this.updatePrev();
      }
      if (this.pos.x < 0) {
        this.pos.x = p.width;
        this.updatePrev();
      }
      if (this.pos.y > p.height) {
        this.pos.y = 0;
        this.updatePrev();
      }
      if (this.pos.y < 0) {
        this.pos.y = 0;
        this.updatePrev();
      }
    };
  };
};
