import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import rehypeReact from "rehype-react";
import Color from "color";
import { Link } from "gatsby";

import theme from "../theme/theme";
import { type } from "../theme/typography";

import Layout from "../components/layout";
import Container from "../components/container";
import P5component from "../components/p5js/p5component";
import ResponsiveVideo from "../components/responsive-video";
import Content from "../components/markdown-content";
import Nav from "../components/nav";
import SEO from "../components/seo";

// Import P5js sketches used in work pages here, then map sketches to project.
// This is lame, but can't do this dynamically.
import flowfield from "../components/p5js/flowfield";
import scatterplot from "../components/p5js/scatterplot";
import maze from "../components/p5js/maze";
import boxes from "../components/p5js/boxes";
import bars from "../components/p5js/bars";

const p5sketches = {
  upwind: flowfield,
  variance: scatterplot,
  "sketch-systems": maze,
  subform: boxes,
  weathertron: bars
};

const TextWrapper = styled.div`
  flex: 1 0 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 24px -${theme.spacing.small} 24px -${theme.spacing.small};
  &:last-child div {
    padding-bottom: 0;
  }
  ${theme.breakpoint.down("medium")`
    margin: 0;
    &:first-child {
      margin-top: 48px;
    }
  `};
  ${theme.breakpoint.down("small")`
    &:first-child {
      margin-top: 36px;
    }
  `}
`;

const Text = styled.div`
  padding: ${theme.spacing.small};
  flex: 0 1
    ${props =>
      /^-?\$?[0-9]*\.?([0-9]{2})?%/.test(props.width) ? props.width : "100%"};
  margin-left: ${props =>
    /^-?\$?[0-9]*\.?([0-9]{2})?%/.test(props.width) ? 0 : "auto"};
  margin-right: ${props =>
    /^-?\$?[0-9]*\.?([0-9]{2})?%/.test(props.width) ? 0 : "auto"};

  &:first-child {
    margin-top: 0;
  }

  ${theme.breakpoint.up("large")`
      max-width: ${theme.variables.siteMeasure}px;
  `};

  ${theme.breakpoint.down("medium")`
    flex: 1 1 100%;
    padding: 0;
    margin: 28px 0 0 0;
  `};
`;

const AssetWrapper = styled.div`
  flex: 1 0 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -${theme.spacing.xsmall};

  ${theme.breakpoint.down("medium")`
    margin: 28px -8px 28px -8px;
  `};
`;

const Asset = styled.div`
  flex: 0 1
    ${props =>
      /^-?\$?[0-9]*\.?([0-9]{2})?%/.test(props.width) ? props.width : "100%"};
  padding: ${theme.spacing.xsmall};

  ${theme.breakpoint.down("medium")`
    flex: 1 0 100%;
    padding: 8px;
  `};
`;

const Header = styled.div`
  overflow: hidden;
  position: relative;
  background: ${props => props.backgroundColor};
`;

const P5Wrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TitleWrapper = styled(Container)`
  padding-top: ${theme.spacing.xxlarge};
  position: relative;
  z-index: 2;
  hr {
    height: 1px;
    border: none;
    background-color: ${props => Color(props.color).fade(0.8).string()};
    margin: 48px 0 0 0;
    width: 100%;
    ${theme.breakpoint.down("small")`
      margin: 36px 0 0 0;
    `};
  }
`;

const NoFigCaptionContent = styled(Content)`
  figcaption {
    display: none;
  }
`

const Title = styled.h1`
  ${type("workHeader1")};
  max-width: 800px;
  margin-bottom: ${theme.spacing.small};
  color: ${props => props.color};
  z-index: 2;
  position: relative;

  ${theme.breakpoint.down("small")`
    ${type("workHeader1mobile")};
  `};
`;

const Intro = styled.p`
  color: ${props => props.color};
  z-index: 2;
  position: relative;
  ${type("body")};
  max-width: 660px;
  ${theme.breakpoint.down("small")`
    ${type("bodySmall")};
  `};
`;

const NextProject = styled.p`
  margin-top: ${theme.spacing.large};
  .arrow {
    color: transparent;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path fill="${props =>
      Color(props.color)
        .rgb()
        .string()}" d="M6.6 12L12 6 6.6 0 5 1.5 8.5 5H0v2h8.5L5 10.5 6.6 12z"/></svg>') no-repeat center center;
  }
`;

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    textwrapper: TextWrapper,
    text: Text,
    assetwrapper: AssetWrapper,
    asset: Asset,
    responsivevideo: ResponsiveVideo
  }
}).Compiler;

export default ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const slug = post.fields.slug.match(/[^/]+(?=\/$|$)/g);
  const image = post.frontmatter.headerImage
    ? post.frontmatter.headerImage.childImageSharp.resize
    : null;

  return (
    <React.Fragment>
    <SEO
        title={post.frontmatter.project + ": " + post.frontmatter.title}
        description={post.frontmatter.introShort}
        image={image}
      />

      <Layout
        backgroundColor={post.frontmatter.backgroundColor}
        bodyColor={post.frontmatter.bodyColor}
        footerColor={post.frontmatter.footerColor}
        highlightColor={post.frontmatter.highlightColor}
        showNav="false"
      >
        <Header backgroundColor={post.frontmatter.headerBackground}>
          <P5Wrapper>
            {p5sketches[slug] ? (
              <P5component sketch={p5sketches[slug]} />
            ) : (
              "TODO: static image header jsx"
            )}
          </P5Wrapper>

          <Nav navColor={post.frontmatter.headlineColor} />

          <TitleWrapper color={post.frontmatter.footerColor} >
            <Title color={post.frontmatter.headlineColor}>
              {post.frontmatter.title}
            </Title>
            <Intro color={post.frontmatter.bodyColor}>
              {post.frontmatter.introLong}
            </Intro>
            <hr />
          </TitleWrapper>
        </Header>

        <NoFigCaptionContent
          bodyColor={post.frontmatter.bodyColor}
          highlightColor={post.frontmatter.highlightColor}
        >
          <Container>
            {renderAst(post.htmlAst)}

            <NextProject color={post.frontmatter.bodyColor}>
              <strong>Next Project</strong>
              <span className="arrow"> →</span>
              <br />
              <Link to={pageContext.next.node.fields.slug}>
                {pageContext.next.node.frontmatter.title}
              </Link>
            </NextProject>
          </Container>
        </NoFigCaptionContent>
      </Layout>
    </React.Fragment>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        slug
      }
      frontmatter {
        project
        title
        introShort
        introLong
        backgroundColor
        headerBackground
        headlineColor
        bodyColor
        footerColor
        highlightColor
        headerImage {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
