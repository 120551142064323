export default p => {
  const canvasWidth = p.windowWidth;
  const canvasHeight = 600;

  const squareSize = 18;
  const spaceBetweenSquares = 1.4;
  const topOffset = 8;

  const buffer = 100;

  let lastWindowWidth, strokeMask, fillMask, randomness;

  p.myCustomRedrawAccordingToNewPropsHandler = function(props, windowWidth) {
    if (windowWidth && lastWindowWidth !== windowWidth) {
      p.resizeCanvas(windowWidth + buffer, canvasHeight);
    }
  };

  p.setup = function() {
    p.disableFriendlyErrors = true;
    p.colorMode(p.HSL);
    p.noLoop();
    p.createCanvas(canvasWidth + buffer, canvasHeight);
    lastWindowWidth = p.windowWidth;
  };

  p.draw = function() {
    p.drawingContext.setLineDash([2, 4]);

    let rows = Math.floor(p.height / squareSize);
    let columns = Math.floor(p.width / squareSize);

    // prettier-ignore
    if (p.width <= 670 + buffer) {
      strokeMask = makeGaussian(1, columns / 2, rows / 3, columns / 4, rows / 7);
      fillMask = makeGaussian(0.8, columns / 2, rows / 3, columns / 4, rows / 7);
      randomness = 0.35;
    } else {
      strokeMask = makeGaussian(1, columns / 2, rows / 3, columns / 3, rows / 5);
      fillMask = makeGaussian(0.8, columns / 2, rows / 3, columns / 3, rows / 5);
      randomness = 0.16;
    }

    for (var x = 0; x < columns - 1; x += spaceBetweenSquares) {
      for (var y = 0; y < rows - 1; y += spaceBetweenSquares) {
        p.push();
        p.translate(squareSize * x, squareSize * y - topOffset);

        // Delta from current column to center column of canvas
        let dx = columns / 2 - x; // outside to inside
        //dx = columns / 2 - Math.abs(x - columns / 2); // original sketch
        //dx = columns / 2 - (x - columns / 2); // random to grid
        //dx =  (x + columns / 2) - (columns / 2); // grid to random

        if (dx !== 0) {
          p.rotate(p.radians(dx * dx * p.random(-randomness, randomness)));
        }
        p.fill(0, 0, 100, fillMask(x, y));
        p.stroke(309, 100, 48, strokeMask(x, y));
        p.rect(0, 0, squareSize, squareSize);
        p.pop();
      }
    }
  };

  p.windowResized = function() {
    // scrolling on iOS triggers a window.resize event for some reason
    // check that window has actually resized before proceeding

    // Notes:
    // Chrome on iOS does not register new width on orientation change until scroll happens
    // Unknown what Android / Firefox do
    if (p.windowWidth !== lastWindowWidth) {
      p.resizeCanvas(p.windowWidth + buffer, canvasHeight);
      p.clear();
      p.redraw();
      lastWindowWidth = p.windowWidth + buffer;
    }
  };

  // Function to create a gaussian distribution to use as a radial gradient mask
  const makeGaussian = (amplitude, x0, y0, sigmaX, sigmaY) => {
    return function(amplitude, x0, y0, sigmaX, sigmaY, x, y) {
      var exponent = -(
        Math.pow(x - x0, 2) / (2 * Math.pow(sigmaX, 2)) +
        Math.pow(y - y0, 2) / (2 * Math.pow(sigmaY, 2))
      );

      return amplitude * Math.pow(Math.E, exponent);
    }.bind(null, amplitude, x0, y0, sigmaX, sigmaY);
  }
};
