export default p => {
  let p5 = require("p5");
  p5.disableFriendlyErrors = true; // disables FES

  let cols, rows;
  let cellWidth = 28;
  let grid = [];
  let current;
  let stack = [];

  let lastWindowWidth;

  const defaultHeight = 616;
  const smallHeight = 392;
  const maxWidth = 1232;

  function resetSketch() {
    grid = [];
    stack = [];
    // Build the grid and throw it into an array
    cols = Math.floor(p.width / cellWidth);
    rows = Math.floor(p.height / cellWidth);

    for (let y = 0; y < rows; y++) {
      for (let x = 0; x < cols; x++) {
        let cell = new Cell(x, y);
        grid.push(cell);
      }
    }

    // Start algorithm at top/left
    current = grid[0];
    p.loop();
  }

  p.setup = () => {
    // Sketch needs to resize at small breakpoints so animation is more visible/faster & height is smaller
    if (p.windowWidth <= 670) {
      p.createCanvas(
        Math.ceil(p.windowWidth / cellWidth) * cellWidth,
        smallHeight
      );
    } else {
      p.createCanvas(
        Math.min(Math.ceil(p.windowWidth / cellWidth) * cellWidth, maxWidth),
        defaultHeight
      );
    }

    lastWindowWidth = p.windowWidth;

    p.strokeCap(p.PROJECT); // Square stroke caps
    p.colorMode(p.HSL);

    resetSketch();
  };

  p.windowResized = function() {
    // scrolling on iOS triggers a window.resize event for some reason
    // check that window has actually resized before proceeding

    // Notes:
    // Chrome on iOS does not register new width on orientation change until scroll happens
    // Unknown what Android / Firefox do

    // Let's not do unnecessary redraws. Only resize if new window width is greater than old one, or we've gone below the small breakpoint threshold
    if (p.windowWidth > lastWindowWidth || (p.windowWidth <= 670 && p.windowWidth !== lastWindowWidth)) {
      if (p.windowWidth <= 670) {
        p.resizeCanvas(
          Math.ceil(p.windowWidth / cellWidth) * cellWidth,
          smallHeight
        );
      } else {
        p.resizeCanvas(
          Math.min(Math.ceil(p.windowWidth / cellWidth) * cellWidth, maxWidth),
          defaultHeight
        );
      }
      lastWindowWidth = p.windowWidth;
      resetSketch();
    }
  };

  p.draw = () => {
    // Need to draw a background to allow for lines to have transparency, otherwise will just be drawn on top of each other
    p.background(180, 9, 98);

    // Draw the
    for (let i = 0; i < grid.length; i++) {
      grid[i].show();
    }

    current.visited = true;

    // Depth-first search algoirthm with recursive backtracking
    // Based on https://thecodingtrain.com/CodingChallenges/010.1-maze-dfs-p5.html

    // Step 1: Grab a neighbor of current cell at random and mark it as next
    let next = current.checkNeighbors();
    if (next) {
      next.visited = true;

      // Step 2: Push the current cell to the stack
      stack.push(current);

      // Step 3: Add walls between current cell and next cell
      addWalls(current, next);

      // Step 4: Make the neighbor into the current cell
      current = next;
    } else if (stack.length > 0) {
      // Pop the old current cell off the stack
      current = stack.pop();
    } else {
      // Drawing is finished, stop the loop
      p.noLoop();
    }
  };

  function index(x, y) {
    if (x < 0 || y < 0 || x > cols - 1 || y > rows - 1) {
      return -1;
    }

    return x + y * cols;
  }

  // Cell object
  function Cell(x, y) {
    this.x = x;
    this.y = y;
    this.walls = [false, false]; // top, right
    this.visited = false;

    this.checkNeighbors = function() {
      let neighbors = [];

      let top = grid[index(x, y - 1)];
      let right = grid[index(x + 1, y)];
      let bottom = grid[index(x, y + 1)];
      let left = grid[index(x - 1, y)];

      if (top && !top.visited) {
        neighbors.push(top);
      }
      if (right && !right.visited) {
        neighbors.push(right);
      }
      if (bottom && !bottom.visited) {
        neighbors.push(bottom);
      }
      if (left && !left.visited) {
        neighbors.push(left);
      }

      if (neighbors.length > 0) {
        let r = Math.floor(p.random(0, neighbors.length));
        return neighbors[r];
      } else {
        return undefined;
      }
    };

    this.show = function() {
      let cellX = this.x * cellWidth;
      let cellY = this.y * cellWidth;

      p.strokeWeight(1);
      const strokeHue = 221;
      const strokeSaturation = 39;
      const strokeLightness = 66;

      var fade = p.height * 0.35;

      // Simulate fade in/out at top/bottom via changing stroke opacity
      if (cellY < fade) {
        p.stroke(
          strokeHue,
          strokeSaturation,
          strokeLightness,
          (1 / 200) * cellY
        );
      } else if (cellY > p.height - fade) {
        p.stroke(
          strokeHue,
          strokeSaturation,
          strokeLightness,
          (1 / (p.height - fade)) * (p.height - cellY)
        );
      } else {
        p.stroke(strokeHue, strokeSaturation, strokeLightness);
      }

      if (this.walls[0]) {
        p.line(cellX, cellY, cellX + cellWidth - 1, cellY);
      }
      if (this.walls[1]) {
        p.line(
          cellX + cellWidth,
          cellY,
          cellX + cellWidth,
          cellY + cellWidth - 1
        );
      }
    };
  }

  function addWalls(a, b) {
    let x = a.x - b.x;
    if (x === 1) {
      b.walls[1] = true;
    } else if (x === -1) {
      a.walls[1] = true;
    }

    let y = a.y - b.y;
    if (y === 1) {
      a.walls[0] = true;
    } else if (y === -1) {
      b.walls[0] = true;
    }
  }
};
